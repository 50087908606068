<template>
  <div
    class="flex flex-col gap-8"
  >
    <template
      v-for="(section, index) in props.sections"
      :key="section.id || index"
    >
      <section
        class="grid grid-cols-[3em_1fr] laptop:grid-cols-[4.2em_1fr] gap-2"
      >
        <div
          class="laptop:row-span-2 flex bg-primary aspect-square h-[3em] w-[3em] laptop:h-[4.2em] laptop:w-[4.2em] rounded-lg"
        >
          <font-awesome-layers
            v-if="section.negateIcon"
            class="text-white text-[1.7em] laptop:text-[2.5em] m-auto"
            fixed-width
          >
            <font-awesome-icon :icon="section.icon"/>
            <font-awesome-icon :icon="['fas', 'slash']" class="text-primary top-1" transform="down-2"/>
            <font-awesome-icon :icon="['fas', 'slash']"/>
          </font-awesome-layers>
          <font-awesome-icon
            v-else
            :icon="section.icon"
            class="text-white text-[1.7em] laptop:text-[2.5em] m-auto"
            fixed-width
          />
        </div>
        <h4
          class="my-auto laptop:my-0 text-slate-900"
        >
          {{ count ? index + 1 + ". " : "" }}{{ section.title }}
        </h4>
        <p
          class="col-span-2 laptop:col-span-1 text-slate-900"
        >
          {{ section.text }}
        </p>
      </section>
    </template>
  </div>
</template>

<script setup lang="ts">
import type {Icon} from "~/utils/types";
import {FontAwesomeIcon, FontAwesomeLayers} from "@fortawesome/vue-fontawesome";

const props = defineProps<{
  sections: {
    id?: string
    icon: Icon
    negateIcon?: boolean
    title: string
    text: string
  }[]
  count?: boolean
}>()
</script>
